<template>
  <v-dialog
      v-model="dialoge"
      persistent
      max-width="800"
  >
    <v-card>
      <v-card-title ><span class="font-bold">{{title}} Templates</span></v-card-title>
      <v-divider/>
      <v-card-text class="pa-5 pt-0">
        <div
              v-for="(template,i) in mailTemplates[slug]"
             :class="{ 'row mt-4': true, 'border-bottom': i !== mailTemplates[slug].length - 1 }"
             :key="i">
          <div class="col-9">
            <p class="font-medium text-base mt-3" style="line-height: 0px ; color: black">{{template.subject}}</p>
            <p class=" font-normal" style="line-height: 0px">{{template.last_updated_at}}</p>
          </div>
          <div class="col-3 text-right">
            <v-btn
                color="teal-color"
                dark
                class="ma-2  shadow-0"
                v-if="template.status_id==1"
                @click="moveToEditTemplate(template.id)"
            >
              Active
            </v-btn>


            <v-btn
                class="ma-2 black--text shadow-0" style="background-color: rgba(17, 42, 70, 0.1) ; text-transform: capitalize"

                dark
                v-if="template.status_id==2"
                @click="moveToEditTemplate(template.id)"
            >
              Draft
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-card-actions  style="background-color: rgba(248, 250, 251, 1)">
        <v-btn text color="" @click="()=>{this.$emit('closeModal')}"> Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn   v-if="$modules.marketing[type] && checkWritePermission($modules.marketing[type].slug)"
               class="ma-2 white--text blue-color shadow-0"
               color="darken-1"
               @click="moveToEditTemplate()">Create New</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TemplatesListingModal",
  props:{
    mailTemplates: { type: Object, default: null },
    slug: { type: String, default: null },
    type: { type: String, default: null },
    title: { type: String, default: null },
    dialoge: { type: Boolean, default: false },

  },
  data() {
    return {
      items:[

      ]
    };
  },


  mounted(){

  },

  methods:{
    moveToEditTemplate(id=null){
      this.$router.push({
        name: "EmailTemplateConfiguration",
        params: { type: 'email_template_configuration', slug:this.slug , id},
      });
    }
  }

};
</script>

<style>

</style>
