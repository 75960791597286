<template>
  <v-container fluid>

    <v-row dense>
      <v-col lg="12" md="12" sm="12">
        <p class="font-bold text-base" style="margin: 0 ; padding: 0" >Trigger Emails</p>
      </v-col>
      <template  v-for="(item , i ) in emailTemplates" >
        <div class="col-xl-3  col-lg-4  col-md-4 col-sm-6 "  :key="i"
             v-if="$modules.marketing[item.type] && checkReadPermission($modules.marketing[item.type].slug)"
              v-bind="item"
             style="font-weight: 500; font-size: 16px; color: #112A46" >
          <v-card
              class="mx-auto shadow-0"
              rounded
              style="border: 1px solid #DCDCDC ; height: 76px"
          >
            <v-card-text class="font-weight-bold">
              <v-row >
                <div class="col-12 " >
                  <div class="d-flex justify-space-between ">
                    <div class="d-flex gap-x-4 align-center" >
                      <SvgIcon class="font-bold text-base  blue-text p-0 m-0" :text="item.name">
                        <template #icon >
                          <component v-bind:is="item.icon" component="svg"/>
                        </template>
                      </SvgIcon>

                    </div>
                    <v-hover v-slot="{ hover }">
                      <v-btn class="shadow-0 text-capitalize align-center"
                             @click="setUpTemplate(item)"
                             :style="{
                        'background-color': hover ? '#112A46' : 'rgba(17, 42, 70, 0.2)',
                        'color': hover ? 'rgba(255, 255, 255, 1)' : 'rgba(17, 42, 70, 1)',
                        }"
                      >Setup</v-btn>
                    </v-hover>
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </v-row>

    <v-row dense class="mt-6">
      <v-col lg="12" md="12" sm="12">
        <p class="font-bold text-base" style="margin: 0 ; padding: 0" >Social Media</p>
      </v-col>
      <template  v-for="(item ,i) in socialMedia"  >
        <v-col lg="3" md="3" sm="6"  v-bind="item" :key="i"
               v-if="checkReadPermission($modules.marketing[item.type].slug)"
               style="font-weight: 500; font-size: 16px; color: #112A46">
          <v-card
              class="mx-auto shadow-0"
              rounded
              style="border: 1px solid #DCDCDC"
          >
            <v-card-text class="font-weight-bold">
              <v-row >
                <v-col md="9" class="d-flex align-center">
                  <FacebookIcon v-if="item.title=='Facebook'"/>
                  <InstagramIcon v-if="item.title=='Instagram'"/>
                  <XIcon v-if="item.title=='X'"/>
                  <KrewsIcon v-if="item.title=='Krews'"/>
                  <div class="title font-bold soc_title ml-4 blue-text" v-if="item.title!='X' && item.title!='Krews'">{{item.title}}</div>
                </v-col>

                <v-col md="3" class="text-right align-center">
                  <div  class="text-center blue-text" style="font-size: 24px ; font-weight: 500">0</div>
                  <div  class="post text-center" style="font-size: 10px">Total Posts</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row dense class="mt-6">
      <v-col lg="12" md="12" sm="12">
        <p class="font-bold text-base" style="margin: 0 ; padding: 0">Email & SMS Unit</p>
      </v-col>
      <v-col lg="3" md="3" sm="6"
             v-if="checkReadPermission($modules.marketing['sms'].slug)"
             style="font-weight: 500; font-size: 16px; color: #112A46" >
        <router-link :to="`/marketing/message/sms`">
        <v-card
            class="mx-auto shadow-0 mt-2"
            style="border: 1px solid #DCDCDC"
        >
          <v-card-text class="font-weight-bold">
            <v-row  class="d-flex" justify="center">
              <v-col md="6">
                <span style="display: flex; gap: 15px">
                <MessageIcon/>
                  <p style="font-weight: 500" class="text-base blue-text">SMS</p>
                </span>
                <p class="mt-5">Total Posts</p>
              </v-col>
              <v-col md="6" >
                <div  class="text-right text-neon text-base">{{data.sms.count}}/{{data.sms.total}}</div>
                <div  class="post text-right text-neon text-xs" >Remaining</div>
                <div  class="text-right mt-5 blue-text" style="font-size: 24px ; font-weight: 500">{{data.sms.total}}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </router-link>
      </v-col>

      <v-col lg="3" md="3" sm="6"
             v-if="checkReadPermission($modules.marketing['email'].slug)"
             style="font-weight: 500; font-size: 16px; color: #112A46">
        <router-link :to="`/marketing/message/email`">
        <v-card
            class="mx-auto shadow-0 mt-2"
            style="border: 1px solid #DCDCDC"
        >
          <v-card-text class="font-weight-bold">
            <v-row  class="d-flex" justify="center">
              <v-col md="6">
                <span style="display: flex; gap: 15px">
                <EnvelopeIcon/>
                  <p style="font-weight: 500" class="text-base blue-text">Emails</p>
                </span>
                <p class="mt-5">Total Posts</p>
              </v-col>
              <v-col md="6" >
                <div  class="text-right text-neon text-base">{{data.email.count}}/{{data.email.total}}</div>
                <div  class="post text-right text-neon text-xs" >Remaining</div>
                <div  class="text-right mt-5 blue-text" style="font-size: 24px ; font-weight: 500">{{data.email.total}}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </router-link>
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col lg="12" md="12" sm="12">
        <p class="font-bold text-base" style="margin: 0 ; padding: 0">Newsletter</p>
      </v-col>
      <v-col lg="3" md="3" sm="6"
             v-if="checkReadPermission($modules.marketing['newsletter'].slug)"
             style="font-weight: 500; font-size: 16px; color: #112A46">
        <router-link :to="`/marketing/newsletter`">
          <v-card
              class="mx-auto shadow-0"
              rounded
              style="border: 1px solid #DCDCDC"
          >
            <v-card-text class="font-weight-bold">
              <v-row align="center" justify="center">
                <div class="col-12 d-flex justify-space-between align-center">
                  <div class="d-flex gap-x-4" >
                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        class="mt-2"
                    >
                      <NewsletterIcon />
                    </v-btn>
                    <p class="font-bold text-base mt-4 blue-text">Newsletter</p>
                  </div>
                  <div  class="text-center" style="font-size: 24px ; font-weight: 500">{{this.data.newsletter.total}}</div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>

      <v-col lg="3" md="3" sm="6"  style="font-weight: 500; font-size: 16px; color: #112A46">
        <router-link :to="`/marketing/announcement-details`">
          <v-card
              class="mx-auto shadow-0"
              rounded
              style="border: 1px solid #DCDCDC"

              v-if="checkReadPermission($modules.marketing['announcement'].slug)"

          >
            <v-card-text class="font-weight-bold">
              <v-row align="center" justify="center">
                <div class="col-12 d-flex justify-space-between align-center">
                  <div class="d-flex gap-x-4" >
                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        class="mt-2"
                    >
                      <NotificationIcon />
                    </v-btn>
                    <p class="font-bold text-base mt-4 blue-text">Mobile Notifications</p>
                  </div>
                  <div  class="text-center" style="font-size: 24px ; font-weight: 500"></div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col lg="3" md="3" sm="6"  style="font-weight: 500; font-size: 16px; color: #112A46">
        <router-link :to="`/marketing/website-notifications`">
          <v-card
              class="mx-auto shadow-0"
              rounded
              style="border: 1px solid #DCDCDC"
              v-if="checkReadPermission($modules.marketing.website_notification.slug)"
          >
            <v-card-text class="font-weight-bold">
              <v-row align="center" justify="center">
                <div class="col-12 d-flex justify-space-between align-center">
                  <div class="d-flex gap-x-4" >
                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        class="mt-2"
                    >
                      <NotificationIcon />
                    </v-btn>
                    <p class="font-bold text-base mt-4 blue-text">Website Notifications</p>
                  </div>
                  <div  class="text-center" style="font-size: 24px ; font-weight: 500"></div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
    <TemplatesListingModal :dialoge="listingModal" :mailTemplates="mailTemplates" :slug="slug" :title="title" :type="type" @closeModal="closeModal"/>
  </v-container>
</template>
<script>
import EnvelopeIcon from "@/assets/images/misc/envelope.svg";

import FacebookIcon from "@/assets/images/social_media/facebook.svg";
import InstagramIcon from "@/assets/images/social_media/instagram.svg";
import XIcon from "@/assets/images/social_media/x.svg";
import KrewsIcon from "@/assets/images/social_media/krews.svg";
import MessageIcon from "@/assets/images/misc/message_icon.svg";
import SurveyMailIcon from "@/assets/images/marketing/survey-mail.svg";
import WelcomeMailIcon from "@/assets/images/marketing/welcome-mail.svg";
import ReservationIcon from "@/assets/images/marketing/reservation.svg";
import RenewIcon from "@/assets/images/marketing/renew-mail.svg";
import ReminderIcon from "@/assets/images/marketing/reminder-mail.svg";
import RefundIcon from "@/assets/images/marketing/refund-mail.svg";
import PromoIcon from "@/assets/images/marketing/promo.svg";
import PendingIcon from "@/assets/images/marketing/pending.svg";
import OrderCancelIcon from "@/assets/images/marketing/order-cancelation.svg";
import MembershipActiveIcon from "@/assets/images/marketing/memebership-activation.svg";
import ConfirmIcon from "@/assets/images/marketing/confirmation-mail.svg";
import RescheduleIcon from "@/assets/images/marketing/booking-reschedule.svg";
import InvoiceIcon from "@/assets/images/marketing/invoice.svg";
import NewsletterIcon from "@/assets/images/marketing/newsletter.svg";
import NotificationIcon from "@/assets/images/marketing/notification.svg";
import EInvoiceMembershipIcon from "@/assets/images/marketing/einvoice-mail.svg";
import TemplatesListingModal from "@/components/Marketing/TemplatesListingModal.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "MarketingHomePage",
  components: {
    SvgIcon,
    TemplatesListingModal,
    EnvelopeIcon,
    NotificationIcon,
    NewsletterIcon,
    FacebookIcon,
    InstagramIcon,
    XIcon,
    KrewsIcon,
    MessageIcon,
  },
  data(){
    return{
      listingModal:false,
      slug:'',
      title:'',
      type:'',
      mailTemplates:{},
      data:{
        sms:{
          total:0,
          count:0,
        },
        email:{
          total:0,
          count:0
        },
        newsletter:{
          total:0,
        }
      },
      emailTemplates:[
        {
          slug: "registration-confirmation-mail",
          name: "Registration Confirmation Mail",
          icon: WelcomeMailIcon ,
          type:"registration_confirmation_mail"
        },
        { slug: "order-confirmation-mail",
          name: "Order Confirmation Mail",
          icon: ConfirmIcon,
          type:'order_confirmation'
        },
        { slug: "order-cancellation-mail",
          name: "Order Cancellation Mail",
          icon: OrderCancelIcon ,
          type:"order_cancel"
        },
        { slug: "event-cancellation-mail",
          name: "Event Cancellation Mail",
          icon: OrderCancelIcon ,
          type:"event_cancel"
        },

        { slug: "welcome-mail",
          name: "Welcome Mail",
          icon: WelcomeMailIcon ,
          type:"welcome_mail"
        },
        { slug: "member-activation-mail",
          name: "Member Activation Mail",
          icon: MembershipActiveIcon,
          type:"new_member_mail"
        },
        { slug: "renew-member-mail",
          name: "Renew Member Mail",
          icon: RenewIcon,
          type:"renew_member_mail"
        },
        { slug: "survey-mail",
          name: "Survey Mail",
          icon: SurveyMailIcon,
          type:"survey_mail"
        },
        { slug: "e-invoice-mail",
          name: "E-Invoice Mail",
          icon: InvoiceIcon,
          type:"e_invoice_mail"
        },
        { slug: "pending-booking-mail",
          name: "Booking Approval Mail",
          icon: PendingIcon,
          type:"approval_mail"
        },
        { slug: "pending-approval-booking-mail",
          name: "Pending Approval Mail",
          icon: PendingIcon,
          type:"pending_approval_mail"
        },
        {
          slug: "booking-rejection-mail",
          name: "Booking CID Rejection Mail",
          icon: WelcomeMailIcon ,
          type:"booking_rejection_mail"
        },
        { slug: "reminder-mail",
          name: "Reminder Mail ",
          icon: ReminderIcon,
          type:"reminder_mail"
        },
        { slug: "order-reservation-mail",
          name: "Order Reservation Mail",
          icon: ReservationIcon,
          type: 'order_reservation'
        },
        { slug: "facility-booking-reschedule",
          name: "Facility Booking Reschedule",
          icon: RescheduleIcon,
          type: 'order_reservation'
        },
        { slug: "order-refund-mail",
          name: "Order Refund Mail",
          icon: RefundIcon,
          type: 'order_refund'
        },
        { slug: "membership-purchase-mail",
          name: "Membership Purchase Mail",
          icon: MembershipActiveIcon,
          type:'membership_purchase'
        },
        { slug: "promo-code-mail",
          name: "Promo Code Mail",
          icon: PromoIcon,
          type: 'promo_mail'
        },
        // { slug: "new-member-mail",
        // name: "New Member Mail",
        // icon: "@/assets/images/misc/mail_box.png"
        // },
        { slug: "e-invoice-membership-mail",
          name: "E-Invoice Memebership Mail",
          icon: EInvoiceMembershipIcon,
          type: 'e_invoice_membership_mail'
        },
        { slug: "auto-debit-failure",
          name: "Auto Debit Failed Mail",
          icon: EnvelopeIcon,
          type: 'auto_debit_failure'
        },
      ],

      socialMedia:[
        {title:"Instagram",logo:FacebookIcon , type:'instagram'},
        {title:"Facebook",logo:FacebookIcon , type:'facebook'},
        {title:"X",logo:FacebookIcon , type: 'twitter'},
        {title:"Krews",logo:FacebookIcon , type: 'krews'},
      ],
    }
  },
  mounted() {
    this.getMessageCount();
  },
  methods:{
    getMessageCount() {
      this.showLoader("Loading");
      this.$http
          .get("venues/marketing/messages/count")
          .then((response) => {
            if(response.data.email_templates){
              let templates = response.data.email_templates ;
              this.mailTemplates = templates

              this.data.sms.count = response.data.data.sms
              this.data.newsletter.total = response.data.data.newsletter
              this.data.sms.total = response.data.total.sms
              this.data.email.count = response.data.data.email
              this.data.email.total = response.data.total.email
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();

          });
    },

    closeModal(){
      this.listingModal = false;
    },

    setUpTemplate(item){
      this.slug = item.slug;
      this.title = item.name;
      this.type = item.type;
      if(!this.mailTemplates[item.slug] || !this.mailTemplates[item.slug].length){
        this.$router.push({
          name: "EmailTemplateConfiguration",
          params: { type: 'email_template_configuration', slug:item.slug},
        });
      }else{
        this.listingModal = true;
      }
    },


  },

}
</script>

<style scoped>
  .justify_me_center{
    display: flex;
    justify-content: center; /* Center horizontally */
  }
</style>